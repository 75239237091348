<template>
  <div>
    <b-button-toolbar class="text-white">
      <b-button @click="$emit('close')" class="w-100">
        <b-icon icon="chevron-left" />
        {{ this.loops.length > 0 ? "Loops" : "Chapters" }}
      </b-button>
    </b-button-toolbar>
    <hr />
    <b-list-group v-if="!isLoopModal">
      <b-button
        v-for="(item, idx) in videos"
        :key="idx"
        :disabled="item.type == 'expandableChapterHeader'"
        class="m-1"
        style="background-color: transparent; border: none"
        :style="{backgroundColor:(item === currentChapter) ? '' : 'transparent'}"
        @click="selectChapter(item)"
      >
        {{ item.displayName }}
      </b-button>
    </b-list-group>
    <b-list-group v-if="isLoopModal">
      <b-button
        v-for="(item, idx) in loops"
        :key="idx"
        :active="item === currentLoop"
        class="m-1"
        style="background-color: transparent; border: none"
        @click="selectLoop(item)"
      >
        {{ item.displayName }}<br />
        {{ convertTime(item.in) }} | {{ convertTime(item.out) }}
      </b-button>
    </b-list-group>
  </div>
</template>

<script>
export default {
  name: "VideoChapters",
  components: {},
  mixins: [],
  props: {
    videos: {
      default: () => [],
      type: Array,
    },
    loops: {
      default: () => [],
      type: Array,
    },
    currentChapter: {
      type: Object,
      default: () => {},
    },
    currentLoop: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isLoopModal() {
      return this.loops.length > 0
    },
  },
  data() {
    return {}
  },
  methods: {
    selectChapter(chapter) {
      this.$emit("update:currentChapter", chapter)
    },
    selectLoop(item) {
      this.$emit("update:loop", item)
    },
    convertTime(time) {
      var pSeconds = Number(time)
      var h = Math.floor(pSeconds / 3600)
      var m = Math.floor((pSeconds % 3600) / 60)
      var s = Math.floor((pSeconds % 3600) % 60)
      return (
        (h > 0 ? (h < 10 ? "0" + h : h) + ":" : "") +
        (m < 10 ? "0" + m : m) +
        ":" +
        (s < 10 ? "0" + s : s)
      )
    },
  },
}
</script>
